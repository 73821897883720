import React, {useEffect} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import {Autocomplete, Button, TextField} from "@mui/material";
import {t} from "i18next";
import {getCustomPages, insertNewPageType, updateCustomPage} from "../../services/createCustomHtmlService";
import styles from './CreateHtmlTemplate.module.scss';
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";

const CreateHtmlTemplate = () => {

const [newPage, setNewPage] = React.useState({});
    const [allPages, setAllPages] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState(null);
    const [editedPage, setEditedPage] = React.useState(null);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const handlePageTypeDropdownChange = (e, value) => {
        setSelectedType(value);
        const selectedPage = allPages.find(page => page.type === value);
        setEditedPage(selectedPage);
    }
    const getAllPages = async () => {
        const response = await getCustomPages();
        if (!!response.data)
            setAllPages(response.data);
    }

    const handleUpdatePage = async () => {
        const response = await updateCustomPage(editedPage);
        setToastContent((!!response.data) ? 'Page updated successfully' : 'Error updating page');
        toggleToast();
    }


    useEffect(() => {
        getAllPages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeSaveObject = (e) => {
        const {name, value} = e.target;
        console.log(name, value);
        setEditedPage({...editedPage, [name]: value});
    }

    const setNewPageData = (e) => {
        const {name, value} = e.target;
        setNewPage({...newPage, [name]: value});
    }

    const isLinkOrTypeExist = (link, type) => {
        return allPages.find(page => page.link === link || page.type === type);
    }

    const saveNewPage = () => {
        const response = insertNewPageType(newPage);
        if(!!response.data){
            setToastContent('Page added successfully');
            toggleToast();
        }else{
            setToastContent('Error adding page');
            toggleToast();
        }
    }

    return (
        <div>
            <BreadcrumbsBar/>
            <Card title={"Create Html Template"}>

                {allPages && allPages.length > 0 &&
                    <div className={styles.container}>
                        <div>
                            <Autocomplete
                                name="type"
                                freeSolo
                                required
                                sx={{width: 350}}
                                value={allPages.type}
                                options={allPages.map((option) => option.type)}
                                onChange={handlePageTypeDropdownChange}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('create-html-pages.page-types')}/>}/>
                            <div className={styles.newPageContainer}>
                                <div className={styles.newPageTitle}>Add New Page</div>
                                <TextField label={t('create-html-pages.page-type')}
                                           name="type"
                                           value={newPage.type}
                                           sx={{
                                                  width: '300px'
                                           }}
                                           onChange={setNewPageData}></TextField>
                                <TextField
                                    label={t('create-html-pages.page-link')}
                                    name="link"
                                    value={newPage.link}
                                    sx={{
                                        width: '300px'
                                    }}
                                    onChange={setNewPageData}></TextField>
                                <Button
                                    variant={"contained"}
                                    onClick={saveNewPage}
                                    sx={{width:'fit-content'}}
                                    color={"info"}
                                    disabled={!newPage.type || !newPage.link || isLinkOrTypeExist(newPage.link,newPage.type)}>Add New Page</Button>
                            </div>
                        </div>
                        {selectedType && <div className={styles.previewHolder}>
                            <div className={styles.previewTitle}>Preview</div>
                            <div className={styles.pageItself}>
                                <div className={`${styles.imageHolder} ${styles.dragBlock}`}>
                                    <img src={editedPage.image_url}
                                         alt={editedPage.type}/>
                                    <TextField label={t('create-html-pages.page-image-url')}
                                               name="image_url" onChange={changeSaveObject}
                                               value={editedPage?.image_url}/>
                                </div>
                                <div className={`${styles.titleHolder} ${styles.dragBlock}`}>
                                    <TextField label={t('create-html-pages.page-title')} name="title"
                                               value={editedPage.title}
                                               onChange={changeSaveObject}/>
                                </div>
                                <div className={`${styles.titleHolder} ${styles.dragBlock}`}>
                                    <TextField label={t('create-html-pages.page-link')} name="link"
                                               value={editedPage.link}
                                               onChange={changeSaveObject}/>
                                    <Button variant="contained"
                                            color="primary"
                                            href={`/cp?l=${editedPage.link}`}
                                            target={"_blank"}>Preview</Button>
                                </div>
                                <div className={`${styles.dragBlock}`}>
                                    <TextField label={t('create-html-pages.page-description')} name="description"
                                               value={editedPage.description}
                                               sx={{width: '800px'}}
                                               multiline={true}
                                               onChange={changeSaveObject}/>
                                </div>
                                <div className={`${styles.dragBlock}`}>
                                    <TextField label={t('create-html-pages.page-cta-link')} name="cta_link"
                                               value={editedPage.cta_link}
                                               sx={{width: '800px'}}
                                               onChange={changeSaveObject}/>
                                </div>
                            </div>
                            <Button variant="contained"
                                    color="info"
                                    onClick={handleUpdatePage}>{t('common.update')}</Button>
                        </div>}
                    </div>
                }
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default CreateHtmlTemplate;
