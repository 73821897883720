import React, {useEffect, useState} from 'react';
import styles from './CustomPageTemplate.module.scss';
import {useSearchParams} from "react-router-dom";
import {getCustomPageDataByLink} from "../../services/createCustomHtmlService";
import {Button} from "@mui/material";

const CustomPageTemplate = () => {
    const [searchParams] = useSearchParams();
    const location = searchParams.get("l");
    const [pageData, setPageData] = useState(null);
    const getCustomPageDataByLocation = async () => {
        const response = await getCustomPageDataByLink(location);
        if (!!response.data) {
            setPageData(response.data[0]);
        }
    }
    useEffect(() => {
        getCustomPageDataByLocation();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className={styles.container}>
            {pageData && <div className={styles.pageItyself}>
                <div className={styles.pageContainer}>
                    {pageData.image_url &&
                        <img src={pageData.image_url} alt={pageData.title} className={styles.image_url}/>}
                    {pageData.title && <div className={styles.titleHolder}>{pageData.title}</div>}
                    {pageData.description && <div className={styles.descHolder}>{pageData.description}</div>}
                    {pageData.cta_link &&
                        <div className={styles.buttonHolder}>
                        <Button variant="contained"
                                color="primary"
                                href={pageData.cta_link}>Detaylı Bilgi</Button>
                        </div>}
                </div>
            </div>}
        </div>
    );
};

export default CustomPageTemplate;
