import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {
    GET_ALL_PAGE_TYPES,
    GET_CUSTOM_PAGE_DATA_BY_LINK,
    INSERT_NEW_PAGE_TYPE,
    UPDATE_CUSTOM_PAGE_DATA
} from "../common/constants/routes";

export const getCustomPages = () => {
    return clientGetWrapper(GET_ALL_PAGE_TYPES);
}

export const updateCustomPage  = (page) => {
    return clientPostWrapper(UPDATE_CUSTOM_PAGE_DATA, page);
}

export const getCustomPageDataByLink = (link) => {
    return clientPostWrapper(GET_CUSTOM_PAGE_DATA_BY_LINK, {link});
}

export const insertNewPageType = (page) => {
    return clientPostWrapper(INSERT_NEW_PAGE_TYPE, page);
}