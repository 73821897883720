import React from 'react';
import styles from "../ProductResults/ProductList.module.scss";
import GenericTable from "../../../common/table/GenericTable";
import {formatTurkishLira, handleLinkClick} from "../../../common/utils";
import {t} from "i18next";
import {Avatar, Button} from "@mui/material";
import {LinkOutlined} from "@mui/icons-material";


const AffiliateResults = ({affiliates, products}) => {

    const affiliateConversionsOutputColumns = {};
    affiliateConversionsOutputColumns.visibility = {}
    affiliateConversionsOutputColumns.data = [
        {
            accessorKey: 'productInfo.image',
            header: "Product Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100}} alt={getProductDataById(cell.row.original.id)?.image}
                        src={getProductDataById(cell.row.original.id)?.image}/>
            )
        },
        {
            accessorKey: 'quantity',
            header: t('common.quantity'),
            enableClickToCopy: true,
        },
        {
            accessorKey: 'id',
            header: t('common.product-id'),
            enableClickToCopy: true

        },
        {
            accessorKey: "Title",
            header: t('common.title'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>
                    <div>{getProductDataById(cell.row.original.id)?.title}</div>
                </div>
            )
        },
        {
            accessorKey: 'productInfo.price',
            header: "Price",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(getProductDataById(cell.row.original.id)?.price)}</div>
            )
        },
        {
            accessorKey: 'productInfo.link',
            header: "Link",
            Cell: (cell) => (
                <Button
                    variant="contained"
                    sx={{mb: 1}}
                    startIcon={<LinkOutlined/>}
                    onClick={(e) => {
                        handleLinkClick(getProductDataById(cell.row.original.id)?.link);
                    }}>
                    NEW TAB
                </Button>
            )
        }
    ]

    const getProductDataById = (id) => {
        //check if products product_id has id or contains id
        return products.find(product => product.client_product_id.includes(id));
    }


    const affiliateColumns = {};
    affiliateColumns.visibility = {}
    affiliateColumns.data = [
        {
            accessorKey: 'id',
            header: "Affiliate Id",
        },
        {
            accessorKey: 'name',
            header: "Affiliate Name",
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'totalAmount',
            header: "Total amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.totalAmount)}</div>
            )
        }
    ];

    const expandAffiliate = ({row}) => {
        const productsMap = row.original.products;
        const products = Object.keys(productsMap).map(key => {
            return {
                id: key,
                quantity: productsMap[key]
            }
        });
        return (
            <GenericTable
                outputColumns={affiliateConversionsOutputColumns}
                tableData={products}
            />
        )
    }

    return (
        <div className={styles.products}>{!!affiliates &&
            <GenericTable
                outputColumns={affiliateColumns}
                tableData={affiliates}
                expand={expandAffiliate}/>
        }</div>
    );
};

export default AffiliateResults;
